<template>
  <div class="page">
    <div class="kgjc-box">
      <span class="title">考纲教材</span>

      <!-- 广告轮播图 -->
      <advBanner
        v-if="bannerArr.length > 0"
        :bannerArr="bannerArr"
        carouselWidth="1200px"
        carouselHeight="470px"
      ></advBanner>
      <!-- 广告轮播图end -->

      <div class="group" v-loading="loading">
        <div class="job" v-for="item in bookList" :key="item.id">
          <img class="picture" :src="item.avatar" />
          <span class="job-title">{{ item.name }}</span>
          <span class="subTitle">
            <span v-if="item.practice_sort_text"
              >{{ item.practice_sort_text }}/</span
            >
            <span v-if="item.practice_scope_text"
              >{{ item.practice_scope_text }}/</span
            >
            <span v-if="item.subspecialty_text">{{
              item.subspecialty_text
            }}</span>
          </span>
          <div class="job-group">
            <!-- <span class="button" @click="xin.message('开发中,敬请期待', 'war')"
              >购买</span
            > -->
            <!-- <span class="button" @click="goUserDate(1)">考纲</span> -->
            <span class="button" @click="goUserDate(2, item)">在线学习</span>
            <span class="button" @click="goUserDate(3, item)">视频讲座</span>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="提示" :visible.sync="dialogVisible" width="560px">
      <!-- :before-close="handleClose" -->
      <span>学习请先进行身份认证</span>
      <div class="popupsButton">
        <span @click="dialogVisible = !dialogVisible" class="popupsJudgment"
          >取消</span
        >
        <span @click="go" class="popupsJudgment">认证</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import advBanner from "@components/common/advBanner";
import { mapState, mapActions } from "vuex";
// import {
// 	mapState
// } from "vuex";

export default {
  components: {
    advBanner,
  },
  data() {
    return {
      loading: false,
      bookList: [],
      swiperList: [],
      isBtn: false,
      bannerArr: [],
      dialogVisible: false, // 控制对话框的显示与隐藏
    };
  },
  async created() {
    this.loading = true;
    this.getBanner();
    await this.getBookList();
    this.loading = false;
  },
  computed: {
    ...mapState("userManagement", ["userInfo"]),
  },

  async mounted() {
    await this.getBannerXin();
  },

  methods: {
    ...mapActions("userManagement", ["getUserInfo"]),

    async getBannerXin() {
      let res = await this.$findApi.banner({
        position: 4,
      });
      if (res.code == 200) {
        this.bannerArr = res.data;
      }
    },
    async goUserDate(index, item) {
      if (!localStorage.getItem("token")) {
        this.xin.message("请登录!", "war");
        this.$emit("method", {
          method: "tabShowLogin",
          options: true,
        });
        return;
      } else if (this.userInfo.company && this.userInfo.company.status != 1) {
        // this.$emit("method", { method: "tabIdentity", options: true });
        // return;
      }
      if (index == 1)
        //考纲
        this.xin.goUrl("/index-syllabus-syllabus?type=9");
      else if (index == 2) {
        //在线学习

        await this.getUserInfo();

        if (this.userInfo.company && this.userInfo.company.status == 1) {
          if (this.isBtn) return;
          this.isBtn = true;
          let ress = await this.$newsApi.is_user_book({
            book_uid: item.book_uid,
          });
          this.isBtn = false;
          if (ress.data) {
            this.$router.push(
              `/index-eLearning?book_uid=${item.book_uid}&name=${item.name}`
            );
          } else {
            let res = await this.$newsApi.getAddBook({
              book_uid: item.book_uid,
            });
            if (res.code == 200) {
              this.$router.push(
                `/index-eLearning?book_uid=${item.book_uid}&name=${item.name}`
              );
            }
          }
        } else {
          // 在线学习非登录状态下
          this.dialogVisible = true;
        }
      }
      if (index == 3)
        //视频讲座
        this.xin.goUrl(
          "/index-syllabus-videoLecture?book_uid=" + item.book_uid
        );
    },
    async getBookList() {
      let { code, data } = await this.$newsApi.getBookList();
      if (code == 200) {
        this.bookList = data;
      }
    },

    async getBanner() {
      // this.xin.message('内容')
      let res = await this.$newsApi.getBanner({
        cate_id: 2,
      });
      if (res.code == 200) {
        this.swiperList = res.data;
      }
    },
    go() {
      this.dialogVisible = false;
      this.$router.push({
        path: "/authentication",
      });
    },

    // 新闻和广告的路径跳转
    jumpTo(url, id, type) {
      if (type != 3) {
        this.$router.push({
          path: "/index-asdvertising",
          query: {
            is_adv: id,
          },
        });
      } else {
        window.open(url, "_blank");
      }
    },

    // learning() {
    // 	this.$confirm('学习请先进行身份认证', '提示', {
    // 		confirmButtonText: '认证',
    // 		cancelButtonText: '取消'
    // 	}).then(() => {
    // 		this.$router.push({
    // 			path: '/authentication'
    // 		})
    // 	})
    // }
  },
};
</script>


<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.page {
  > .kgjc-box {
    display: flex;
    flex-direction: column;
    width: 1200px;
    margin: 0 auto;
    margin-top: 30px;

    > .title {
      font-size: 18px;
      color: #333333;
      font-weight: 600;
      margin-bottom: 23px;
    }

    > img {
      width: 1200px;
      height: 300px;
      object-fit: cover;
      border-radius: 20px;
      margin-bottom: 40px;
    }

    > .group {
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between; //内容向两边靠
      min-height: 400px;

      > .job {
        display: flex;
        flex-direction: column;
        border-radius: 10px 10px 0 0;
        overflow: hidden;
        background: #f2f2f2;
        font-size: 18px;
        color: #333333;
        margin-bottom: 15px;

        > .picture {
          width: 390px;
          height: 275px;
          object-fit: cover;
        }

        .job-title {
          font-size: 24px;
          color: #333333;
          margin: 17px 0 10px 15px;
        }

        > .subTitle {
          line-height: 1.8;
          width: 360px;
          margin-left: 15px;
          margin-bottom: 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        > .job-group {
          display: flex;
          flex-direction: row;
          font-size: 16px;
          width: 373px;
          padding: 0 10px;
          box-sizing: border-box;
          margin-left: 9px;

          > .button {
            cursor: pointer;
            flex: 1;

            // border: 1px solid $--themeColor;
            &:nth-child(1) {
              border: 1px solid $--themeColor;
              color: $--themeColor;
            }

            &:nth-child(2) {
              border: 1px solid #00db68;
              color: #00db68;
            }

            &:nth-child(3) {
              border: 1px solid #01c6d6;
              color: #01c6d6;
            }

            &:last-child {
              border: 1px solid #0390db;
              color: #0390db;
              margin-right: 0;
            }

            color: $--themeColor;
            width: 84px;
            height: 37px;
            background: #ffffff;

            border-radius: 11px;
            text-align: center;
            // line-height: 35px;
            margin-bottom: 30px;
            margin-right: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-bottom: 2px;
          }
        }
      }
    }
  }
}

::v-deep {
  .text-container {
    width: 100% !important;
  }

  .el-dialog {
    display: flex;
    flex-direction: column;
    padding: 70px 80px;
    box-sizing: border-box;
    margin-top: 320px;

    > .el-dialog__body {
      font-size: 24px;
      color: #333333;
      text-align: center;
    }

    .popupsButton {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      cursor: pointer;

      > .popupsJudgment {
        &:last-child {
          background: $--themeColor;
          border: none;
          color: #ffffff;
        }

        width: 170px;
        height: 50px;
        background: #ffffff;
        border: 1px solid $--themeColor;
        border-radius: 26px;
        color: $--themeColor;
        font-size: 18px;
        text-align: center;
        line-height: 50px;
        margin-top: 60px;
      }
    }
  }

  .el-carousel__item .banner {
    width: 890px;
    height: 400px;
    object-fit: cover;
  }

  .el-carousel__arrow {
    display: none;
  }
}
</style>
